@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: black;
	font-style: normal;
	src: url('/public/fonts/FrutigerLTCom-Black.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: bold;
	font-style: normal;
	src: url('/public/fonts/FrutigerLTCom-Bold.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: bold;
	font-style: italic;
	src: url('/public/fonts/FrutigerLTCom-BoldItalic.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: normal;
	font-style: italic;
	src: url('/public/fonts/FrutigerLTCom-Italic.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: light;
	font-style: normal;
	src: url('/public/fonts/FrutigerLTCom-Light.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom';
	font-weight: light;
	font-style: italic;
	src: url('/public/fonts/FrutigerLTCom-LightItalic.ttf');
}

@font-face {
	font-family: 'FrutigerLTCom-Roman';
	font-weight: normal;
	font-style: normal;
	src: url('/public/fonts/FrutigerLTCom-Roman.ttf');
}

* {
	padding: 0;
	margin: 0;
}

html {
	height: 100%;
}

body {
	font-family: 'FrutigerLTCom';
	font-weight: normal;
	font-style: normal;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* CI */
	background-color: rgb(2, 10, 15);
	color: #ffffff;
	font-size: 22px;
	height: 100%;
}

.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

h1 {
	font-size: 30px;
	font-weight: bold;
	padding: 0px 0px 40px;
}

h2 {
	font-size: 30px;
	font-weight: bold;
	padding: 0px 0px 20px;
}

h3 {
	font-size: 30px;
}

p {
	font-size: 22px;
}

@media screen and (min-width: 800px) {
	h2 {
		font-size: 40px;
		font-weight: bold;
		padding: 0px 0px 30px;
	}
}

.richtext a {
	display: inline-block;
	color: #2993a2;
}
.richtext a,
.richtext img {
	display: inline-block;
	/* vertical-align: bottom; */
}

button {
	border-radius: 9999px;
	padding: 8px 32px;
	border: 4px white solid;
	font-weight: bold;
}

button.primary {
	border-radius: 9999px;
	padding: 8px 32px;
	border: 4px #2993a2 solid;
}

a:active,
.icon:active {
	filter: brightness(0.5);
}

/* SCROLLBAR */
.scrollbar {
	--size: 8px;
}
.scrollbar::-webkit-scrollbar {
	width: calc(var(--size) * 4);
}
.scrollbar::-webkit-scrollbar-track,
.scrollbar::-webkit-scrollbar-thumb {
	background-color: #1e1e1e;
	border-radius: 9999px;
	border: calc(var(--size)) solid transparent;
	background-clip: content-box;
}
.scrollbar::-webkit-scrollbar-thumb {
	background-color: #716e6e;
}

/* ARTICLE */
.article {
	display: grid;
}

.article:last-child {
	padding-bottom: 200px;
}

@media screen and (max-width: 800px) {
	.article {
		align-content: start;
		grid-gap: 50px;
		padding: 25px;
	}
	.bubble {
		justify-self: center;
		width: 150px;
		height: 150px;
	}
}

@media screen and (min-width: 800px) {
	.article {
		grid-template-columns: 1fr 1fr;
		justify-items: center;
		min-height: 100vh;
		padding: 50px;
	}
	.bubble {
		position: sticky;
		top: calc(50vh - 150px);
		width: 300px;
		height: 300px;
	}
}
